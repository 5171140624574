import(/* webpackMode: "eager", webpackExports: ["PageProvider"] */ "/workdir/apps/wla/app/(cms)/[...slug]/page-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workdir/apps/wla/app/intl-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingBar"] */ "/workdir/apps/wla/app/loading-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SalesForceScripts"] */ "/workdir/apps/wla/components/ui/sales-force-scripts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "/workdir/apps/wla/lib/configs/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomerSessionProvider"] */ "/workdir/apps/wla/lib/hooks/customer-session/customer-session-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FavoriteStoreProvider"] */ "/workdir/apps/wla/lib/hooks/favorite-store/favorite-store-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomerFavouritesProvider"] */ "/workdir/apps/wla/lib/hooks/favourites/customer-favourites-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NiceModalProvider"] */ "/workdir/apps/wla/lib/hooks/nicemodal-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewRelicProvider"] */ "/workdir/apps/wla/lib/hooks/tracking/new-relic-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShoppingSessionProvider"] */ "/workdir/apps/wla/lib/shopping-session/shopping-session-provider.tsx");
;
import(/* webpackMode: "eager" */ "/workdir/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/workdir/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/workdir/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/workdir/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workdir/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../../libs/icons/font/jysk-icons.woff2\",\"variable\":\"--font-jysk-icons\"}],\"variableName\":\"jyskIcons\"}");
;
import(/* webpackMode: "eager" */ "/workdir/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/open-sans-latin-variable-wghtOnly-normal.woff2\",\"variable\":\"--font-open-sans\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/workdir/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/open-sans-latin-variable-wghtOnly-italic.woff2\",\"variable\":\"--font-open-sans-italic\",\"preload\":false}],\"variableName\":\"openSansItalic\"}");
;
import(/* webpackMode: "eager" */ "/workdir/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/fox_in_the_snow.woff2\",\"variable\":\"--font-fox-in-the-snow\",\"preload\":false}],\"variableName\":\"foxInTheSnow\"}");
;
import(/* webpackMode: "eager" */ "/workdir/apps/wla/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/workdir/apps/wla/styles/salesforce.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/workdir/node_modules/sonner/dist/index.mjs");
