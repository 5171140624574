'use client';

import { SalesForcePopup } from '@wla/components/ui/sales-force-popup';
import { usePublicConfig } from '@wla/lib/configs';
import { CookieTypesEnum, useCookieConsent } from '@wla/lib/hooks/use-cookie-consent';
import Script from 'next/script';
import { useEffect } from 'react';

export function SalesForceScripts() {
  const hasConsent = useCookieConsent(CookieTypesEnum.FUNCTIONAL);
  const { languageISOCode, featureFlags } = usePublicConfig();

  useEffect(() => {
    if (!hasConsent || !featureFlags.enableChat) {
      const script = document.getElementById('salesforce-popup');
      if (script) {
        script.remove();
      }

      const helpButtons = document.getElementsByClassName('embeddedServiceHelpButton');
      if (helpButtons.length > 0) {
        helpButtons[0].remove();
      }
    }
  }, [hasConsent]);

  if (!hasConsent || !featureFlags.enableChat) {
    return null;
  }

  return (
    <>
      <Script
        id="saleforce"
        src={process.env.NEXT_PUBLIC_SALESFORCE_DEPLOYMENT_URL}
        strategy="afterInteractive"
        type="text/javascript"
      />
      <Script
        id="saleforceclient"
        src={`${process.env.NEXT_PUBLIC_SALESFORCE_DEPLOYMENT_EMBEDDED}/embeddedservice/5.0/esw.min.js`}
        strategy="afterInteractive"
        type="text/javascript"
      />
      <Script
        id="salesforce-popup"
        src={`${process.env.NEXT_PUBLIC_SALESFORCE_CHAT_URL}/chat/rest/EmbeddedService/EmbeddedServiceConfig.jsonp?Settings.prefix=EmbeddedService&org_id=${process.env.NEXT_PUBLIC_SALESFORCE_ORG_ID}&EmbeddedServiceConfig.configName=Jysk_Snap_ins&callback=embedded_svc.liveAgentAPI.handleChatSettings&version=60&EmbeddedServiceConfig.language=${languageISOCode}`}
        strategy="afterInteractive"
        type="text/javascript"
      />
      <SalesForcePopup languageISOCode={languageISOCode} />
    </>
  );
}
