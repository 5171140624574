'use client';

import { trackError } from '@wla/lib/tracking/errors/error-tracking';
import { useEffect } from 'react';

type SalesForcePopupProps = {
  languageISOCode: string;
};

export function SalesForcePopup({ languageISOCode }: SalesForcePopupProps) {
  const salesforceOrgId = process.env.NEXT_PUBLIC_SALESFORCE_ORG_ID;
  const salesforceDevId = process.env.NEXT_PUBLIC_SALESFORCE_DEPLOYMENT_ID;
  const salesforceChatUrl = process.env.NEXT_PUBLIC_SALESFORCE_CHAT_URL;
  const salesforceEndpoint = process.env.NEXT_PUBLIC_SALESFORCE_ENDPOINT;
  const salesforceEmbedded = process.env.NEXT_PUBLIC_SALESFORCE_DEPLOYMENT_EMBEDDED ?? '';

  function setupLiveAgent(config: any) {
    const embedded_svc = window.embedded_svc;
    if (!embedded_svc && !config.embeddedServiceConfig && !config.additionalSettings) return;
    const buttonId = config.embeddedServiceConfig?.embeddedServiceLiveAgent?.liveChatButton || 'missing-button-id';

    embedded_svc.settings.language = languageISOCode;
    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';
    embedded_svc.init(
      salesforceEmbedded,
      `${salesforceEndpoint}/chat`,
      'https://service.force.com',
      salesforceOrgId,
      'Chat_Agents',
      {
        baseLiveAgentContentURL: `${salesforceChatUrl}/content`,
        deploymentId: salesforceDevId,
        buttonId: buttonId,
        baseLiveAgentURL: `${salesforceChatUrl}/chat`,
        eswLiveAgentDevName: 'Chat_Agents',
        isOfflineSupportEnabled: false,
      },
    );
  }

  useEffect(() => {
    function loadScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `${salesforceEmbedded}/embeddedservice/5.0/client/liveagent.esw.min.js`;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          reject(new Error('Script load error'));
        };
        document.head.appendChild(script);
      });
    }

    if (!window.embedded_svc) {
      window.embedded_svc = {};
    }

    window.embedded_svc.liveAgentAPI = {
      handleChatSettings: async (response: any) => {
        const config = response.messages[0].message;
        window.embedded_svc.config = config;

        try {
          await loadScript();
          setupLiveAgent(config);
        } catch (error) {
          trackError(error, { languageISOCode });
        }
      },
    };
  }, [languageISOCode]);

  return null;
}
